import {useState, useEffect} from 'react';
import {useNavigate  } from 'react-router-dom';
import "../styles/bootstrap.min.css";
import UserService from "../services/UserService";
import LoginCheck from "./LoginCheck";
import { useTranslation } from 'react-i18next';
import UserAvatar  from "../images/user-avatar.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import Logout  from "../images/logout.png";
import LanguageIcon  from "../images/language-icon.png";

function Topbar() {
  const { i18n } = useTranslation();
  let navigate  = useNavigate();
  const [showLogout, setShowLogout] = useState(false);
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'),10));
  const [currLang, setCurrLang] = useState('en');
  const changeLanguage = (lng) => {
    
    i18n.changeLanguage(lng)
    lng==='en' ? setCurrLang('nor') :  setCurrLang('en') ;
    //lng=='en' ? i18n.changeLanguage('nor') : i18n.changeLanguage('en');
  };
  const { t } = useTranslation();
  const logoutUser = () => {
     window.sessionStorage.setItem('email', '')
      window.location.href = '/'
     // navigate("/")
  }
 
	return (
        <>
            <LoginCheck />
            
            <div className="col-md-3 col-lg-2 d-flex mb-2 mb-md-0 fm-searchbar">
              
              <div className="col-1 d-flex align-items-center" align="center">&nbsp;</div>
              {/*<div className="col-2 d-flex align-items-center" align="center"><img alt="Notification" src={Notification} /></div>
              <div className="col-2 d-flex align-items-center" align="center"><img alt="Theme" src={Theme} /></div>*/}

              <div className="col-1 d-flex align-items-center" align="center">&nbsp;</div>
              
              <div className="col-5 d-flex align-items-center close-btn topbar-links" align="center"  title={t('logout')} onClick={() => logoutUser()}>
              <i className="bi bi-box-arrow-right topbar-icons"></i>  &nbsp; {t('logout')}
              </div>
              <div className="col-1 d-flex align-items-center" align="center">&nbsp;</div>
              <div className="col-5 d-flex align-items-center language-icon  topbar-links" align="center" title={t('language')} onClick={() => changeLanguage(currLang)}>
              <i className="bi bi-globe2 topbar-icons" ></i> &nbsp; {currLang}
              </div>
              {/*<div className="col-3 d-flex align-items-center" align="center"><img alt="Avatar" src={UserAvatar}  /></div> */}
              
            </div>

        </>
        )
}
export default Topbar;