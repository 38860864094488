import {NavLink, useNavigate, useParams  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from "../services/AuthService";
import AssistantService from "../services/AssistantService";
import PromptService from "../services/PromptService";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import JobDescriptionQuestion from "./JobDescriptionQuestion";
import UserAvatar  from "../images/user-avatar.png";
import OurValues  from "../images/our-values.png";
import ToneOfVoice  from "../images/tone-voice.png";
import Language  from "../images/language.png";
import JobResult  from "../images/job-result.png";
import Feedback  from "../images/feedback.png";
import Notification  from "../images/notifications_none.png";
import Theme  from "../images/moon-solid.png";
import Information  from "../images/info_outline.png";
import { useTranslation } from 'react-i18next';
import CloseCircle  from "../images/cross-circle.png";

function JobDescription() {
  const { appslug } = useParams();
  //console.log(appslug)
  document.body.style.backgroundColor = '#FFFFFF';
  const { t } = useTranslation();
  const [savePromptMsg, setSavePromptMsg] = useState("");
  const [assistantID, setAssistantID] = useState("");
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
  const [Q1Data, setQ1Data] = useState("");
  console.log("Q1Data", Q1Data);
  const addQ1Row = () => {

    const newQ1Row = { id: q1Rows.length + 1, heading: '', description: '' };
    setQ1Rows([...q1Rows, newQ1Row]);
  };

  const removeQ1Row = (id) => {
    const newQ1Rows = q1Rows.filter(row => row.id !== id).map((row, index) => ({
      ...row,
      id: index + 1
    }));
    setQ1Rows(newQ1Rows);
  };
    const [q1Question, setQ1Question] = useState();

  const [q1Rows, setQ1Rows] = useState([
    { id: 1, heading: '', description: '' }
  ]);
  //console.log("q1Rows", q1Rows);
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        PromptService.getPromptByType(assistresponse.assistantID, "questionone", organizationID).then(
        (response) => { 
          setQ1Data(JSON.parse(response.prompt.prompt))
         /* const promptData = JSON.parse(response.prompt.prompt);
          setQ1Question(promptData[0].question);
          const questionData = promptData[0].data;
          setQ1Rows( questionData)*/
        },
        (error) => {
          console.log(error);
        });

        PromptService.getPromptByType(assistresponse.assistantID, "userdefined", organizationID).then(
        (response) => {
          if (response && response.prompt && response.prompt.prompt) {
            const promptData = JSON.parse(response.prompt.prompt);
            const updatedTables = promptData.map((item, index) => {
              let rows = [];
              if (Array.isArray(item.a)) {
               rows = item.a.map((value, i) => ({
                  id: `${i}`, // Unique id for each row
                  description: value
                }));

              } else {
                console.error(`Invalid format in item.a for item with id ${item.q}:`, item.a);
              }
              return {
                id: item.q, // Assuming 'q' field as id for the table
                help: item.help, // Assuming 'q' field as id for the table
                rows: rows
              };
            });
            setTables(updatedTables);
          }
        },
        (error) => {
          console.log(error);
        });
        //setAssistantID(response.assistantID);
      }
    )
    

    
    
  },[appslug])
  const [tables, setTables] = useState([
    { id: 0, rows: [{ id: 1, description: ''}] } 
       // Add more tables as needed
  ]);
  //console.log("tables", tables)
  // Function to add a new row to a specific table
  const addRow = (tableId) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRow = { id: table.rows.length + 1, description: '', quantity: '' };
        return { ...table, rows: [...table.rows, newRow] };
      }
      return table;
    });
    setTables(newTables);
  };

  // Function to remove a row from a specific table
  const removeRow = (tableId, rowId) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRows = table.rows.filter(row => row.id !== rowId);
        return { ...table, rows: newRows };
      }
      return table;
    });
    setTables(newTables);
  };

  // Function to handle input change in a row of a specific table
  const handleInputChange = (tableId, rowId, field, value) => {
    const newTables = tables.map(table => {
      if (table.id === tableId) {
        const newRows = table.rows.map(row =>
          row.id === rowId ? { ...row, [field]: value } : row
        );
        return { ...table, rows: newRows };
      }
      return table;
    });
    setTables(newTables);
  };
  
  
  return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('jobdescriptionforseoassistant')}</h1>
                      <div className="breadcrumb">
                        {t('jobdescriptionforseoassistant')}
                      </div>
                    </div>
                    <CreditBar /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('jobdescriptionpagesubline')}</p>
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    <div className="row">

                          <div className="col-12 p-4">
                           {Q1Data ? Q1Data.map((val, key) => {
                            return <>
                              <div className="col-12 job_desc_question_label">
                              <table className="table table-hover align-middle">
                                <tbody>
                                  <tr className="tr-header">
                                  <td>{val.question} <img title={val.help} className="close-btn" src={Information} /></td>
                                  <td align="right"></td>
                                  </tr>
                                </tbody>
                              </table>
                              </div>
                              <div className="col-12">
                              <table className="table table-hover align-middle">
                              <tbody>
                                
                                {val.data.map((row, index) => (
                                <tr key={row.id}>
                                  <td className="col-2">
                                    <input
                                      readonly="true"
                                      type="text"
                                      name={`heading_${row.id}`}
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.heading}
                                      onChange={(e) => {
                                        const newRows = [...q1Rows];
                                        newRows[index].heading = e.target.value;
                                        setQ1Rows(newRows);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      readonly="true"
                                      type="text"
                                      name={`description_${row.id}`}
                                      className="form-control"
                                      placeholder=""
                                      aria-label=""
                                      aria-describedby=""
                                      value={row.description}
                                      onChange={(e) => {
                                        const newRows = [...q1Rows];
                                        newRows[index].description = e.target.value;
                                        setQ1Rows(newRows);
                                      }}
                                    />
                                  </td>
                                  
                                </tr>
                                ))}
                              </tbody>
                              </table>
                              
                            </div>
                              </>
                          }) : ''}
                            
                            

                         </div>
                          {tables.map(table => (
                            <div className="col-12 p-4">
                              <div className="col-12 job_desc_question_label">
                              <table className="table table-hover align-middle">
                              <tbody>
                                <tr className="tr-header">
                                <td>{table.id} <img title={table.help} className="close-btn" src={Information} /> </td>
                                <td align="right"></td>
                                </tr>
                               </tbody>
                              </table> 
                              
                             
                              </div>
                              <div className="col-12">
                                <JobDescriptionQuestion key={table.id}  tableId={table.id} rows={table.rows} addRow={() => addRow(table.id)} removeRow={(rowId) => removeRow(table.id, rowId)} handleInputChange={(rowId, field, value) => handleInputChange(table.id, rowId, field, value)} />
                              </div>
                            </div>
                          ))}
                         
                      
                         <div className="col-12 p-4">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                              <NavLink to={"/"+appslug+"/history"}><button className="btn btn-lg fm-btn me-md-2" type="button" >{t('previousjobdescription')}</button> </NavLink> 
                              <NavLink to={"/"+appslug+"/improve"}><button className="btn btn-lg fm-btn" type="button">{t('improvethejobdescription')}</button></NavLink> 
                              {savePromptMsg ? savePromptMsg : ''}
                            </div>
                         </div>
                      
                        
                        
                     </div>
                     
                  </div>
                  
                  
                </main>
              </div>
            </div>
          
        </div>
        )
}
export default JobDescription;