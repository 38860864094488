import { BrowserRouter, Route, Routes} from "react-router-dom";
import React from 'react';
import LoginCheck from './components/LoginCheck';
import SideBar from "./components/Sidebar";

import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Tools from './components/Tools';
import Sidebar from './components/Sidebar';
import JobDescription from './components/JobDescription';
import JobInstruction from './components/JobInstruction';
import Training from './components/Training';
import AssistantOverview from './components/AssistantOverview';
import CompletedTask from './components/CompletedTask';
import IncompletedTask from './components/IncompletedTask';
import WorkSchedule from './components/WorkSchedule';
import WorkPlan from './components/WorkPlan';
import ImproveJob from './components/ImproveJob';
import Improve from './components/Improve';
import Execute from './components/Execute';
import Register from './components/Register';
import JobHistory from './components/JobHistory';
import PIMConfig from './components/PIMConfig';
import Country from './components/Country';
import './styles/style.css';
import './i18n'; 

const userEmail = window.sessionStorage.getItem('email');
function App() {
  return (
    <div>
      
      
      <BrowserRouter>
        {userEmail ? <SideBar /> : ''}
        <Routes>

        <Route exact path="/" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/country" element={<Country />} />
        <Route exact path="/overview" element={<Dashboard />} />
        <Route exact path="/tools" element={<Tools />} />
        <Route exact path="/tools/pim" element={<PIMConfig />} />

        <Route exact path="/:appslug/job-instruction" element={<JobInstruction />} />
        <Route exact path="/:appslug/job-description" element={<JobDescription />} />
        <Route exact path="/:appslug/training" element={<Training />} />
        <Route exact path="/:appslug/overview" element={<AssistantOverview />} />
        <Route exact path="/:appslug/improve" element={<ImproveJob />} />
        <Route exact path="/:appslug/improveee" element={<Improve />} />
        <Route exact path="/:appslug/work-schedule" element={<WorkSchedule />} />
        <Route exact path="/:appslug/work-plan" element={<WorkPlan />} />
        <Route exact path="/:appslug/history" element={<JobHistory />} />
        <Route exact path="/:appslug/completed-task" element={<CompletedTask />} />
        <Route exact path="/:appslug/incompleted-task" element={<IncompletedTask />} />

        <Route exact path="/execute" element={<Execute />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
