import {useParams, NavLink  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";
import UserService from "../services/UserService";
import PIMServices from "../services/PIMServices";
import ProductService from "../services/ProductService";
import DropdownComponent from "./DropdownComponent";
import AttributeNamesss from "./AttributeNames";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'; // Import the CSS for styling
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CloseIcon  from "../images/close.png";

//import 'semantic-ui-css/semantic.css';
function WorkSchedule() {
  const { appslug } = useParams();

  document.body.style.backgroundColor = '#FFFFFF';
  const [startAgainID, setStartAgainID] = useState(false);
  const [showStartAgainModal, setShowStartAgainModal] = useState(false);
  const [scheduleMsg, setScheduleMsg] = useState("");
  const [setNamesss, setNamessss] = useState("");
  const [workPlanList, setWorkPlanList] = useState([]);
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
   const [totalProductsInCriteria, setTotalProductsInCriteria] = useState(0)
   const [statsSEOGenerated, setStatsSEOGenerated] = useState("");
  const [statsSEOTotal, setStatsSEOTotal] = useState("");
  const { t } = useTranslation();
  const attributeNames = (ids) => {
    AssistantService.GetAttributeNamesByIDs(ids).then(
      (response) => {
          const names = response.data.attributes.map(attr => attr.name); // Get all names
          return names.join(', ');
        
      }
    ).catch(
      (error) => {
        console.error("Error fetching GetAttributeNamesByIDs:", error);  
      }
    )
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Format options
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // Disable 12-hour clock if you want 24-hour format
    };

    return new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', ' at');
  };
  const startAgainWorkPlan = (workPlanID) => {
    AssistantService.startAgainWorkPlanStats(workPlanID).then(
      (response) => {
        console.log(response.data)
        setTotalProductsInCriteria(response.data.total-response.data.generated)
        setStatsSEOGenerated(response.data.generated)
        setStatsSEOTotal(response.data.total)
        setShowStartAgainModal(true); 
        setStartAgainID(workPlanID) 
      }
    ).catch(
      (error) => {
        console.log("error fetching startAgainWorkPlanStats", error)
      }
    )
    
  }
  const StopResumeWorkPlan = (workPlanID, stopresume) => {
    if(stopresume=="stopped"){
      var confirmed = window.confirm("Are you sure you want to stop this workplan?");
    } else if(stopresume=="processing"){ 
      var confirmed = window.confirm("Are you sure you want to resume this workplan?");
    } else if(stopresume=="pending"){ 
      var confirmed = window.confirm("Are you sure you want to restart this workplan?");
    }
    if (confirmed) {
      const updatedWorkPlanList = workPlanList.map(workPlan =>
        workPlan.id === workPlanID ? { ...workPlan, status: stopresume } : workPlan
      );
      setWorkPlanList(updatedWorkPlanList);
      AssistantService.StopResumeWorkPlan(workPlanID, stopresume).then(
        (response) => {
          console.log(response)
        }
      ).catch(
        (error) => {
          console.log("error setting StopResumeWorkPlan");
        }
      )
    }
  }
  useEffect(() => {
    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        AssistantService.GetWorkPlanList(assistresponse.assistantID).then(
          (response) => {
            setWorkPlanList(response.data.workplans)
            //console.log(response);
          }
        ).catch(
          (error) => {
            console.error("Error fetching GetWorkPlanList:", error);  
          }
        )
      }
      ).catch(
        (error) => {
          console.error("Error fetching getAssistantIDBySlug:", error);
        }
      )
     
  }, [])
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                        <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                          <i className="bi bi-list"></i>
                        </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('workschedule')}</h1>
                      <div className="breadcrumb">
                        {t('workschedule')} 
                      </div>
                    </div>
                    <CreditBar /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('workschedulepagesubline')}</p>
                    </div>
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="">
                        <h4 className="h4">{t('listofworkplan')}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row ">
                      {workPlanList ?
                      (
                      <div className="col-12 fm-content-box">
                      <div className="col-12 table-responsive mt-4  fm-table ">
                          
                          <table className="table table-hover align-top">
                               <tbody>
                                  <tr>
                                <th scope="col">{t('selectioncriteria')}</th>
                                <th scope="col"> {t('time')}</th>
                                <th scope="col">{t('status')}</th>
                                <th scope="col">{t('activity')}</th>
                              </tr>
                                
                                {workPlanList.map((value, key) => {
                                  return <>
                                    <tr className={"workplan-"+value.status} title={value.status}>
                                      <td width="30%">
                                        {value.type=="criteria" ? <AttributeNamesss idlist={value.attribute_ids} /> :  value.isbns.substr(0,80)+'...'}
                                      </td>
                                      <td  width="25%">
                                        {value.status=="completed" ? "Completed" : (<> Next Run: {formatDate(value.cron_start_date)} </> ) }
                                      </td>
                                      <td  width="30%">
                                        <div className="creditProgressBarContainer">
                                          <div className="creditProgressBarGreen" style={{width: Math.round(((100*value.generated_products)/value.total_products))+"%" }}>&nbsp;</div>
                                        </div>
                                        <div className="creditProgressText"><b>{t('updated')}:</b> {value.generated_products} {t('outof')} {value.total_products}. <b>{t('remaining')}:</b> {value.total_products-value.generated_products}</div>
                                      </td>
                                      <td width="15%">
                                      
                                        {value.status=="processing" || value.status=="pending" ? 

                                          (
                                          <>
                                          <button onClick={() => StopResumeWorkPlan(value.id, 'stopped') } className="btn btn-md fm-btn me-md-2">{t('stop')}</button>
                                          </>
                                          )
                                        : 
                                        
                                          value.status=="stopped" ? 
                                         (
                                          <>
                                          <button onClick={() => StopResumeWorkPlan(value.id, 'processing') } className="btn btn-md fm-btn me-md-2">{t('resume')}</button>
                                          </>
                                          )
                                          :
                                          (
                                            <><button onClick={() => { startAgainWorkPlan(value.id) } } className="btn btn-md fm-btn me-md-2">{t('startagain')}</button></>
                                          )
                                        }
                                      </td>
                                      {/* */}
                                    </tr>
                                  </>
                                })}
                               
                              
                              
                            </tbody>
                          </table>
                        </div> 
                        </div> 
                        )
                        : (
                          <div className="col-10 table-responsive mt-4">
                          {t('youhavenoworkplans')}
                          </div>
                        )}
                    </div>
                  </div>
                  
                  <div className="container-fluid mt-4">
                    
                    <div className="row" >
                      <div className="col-12">
                        <NavLink to={"/"+appslug+"/work-plan"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('setupworkplan')}</button> </NavLink> 
                      </div>
                    </div>  
                      
                  </div>

                  
                  {showStartAgainModal ? 
                    <div className="modal display-block">
                      <div className="modal-main">
                        <div style={{float: 'right', marginTop: '-15px', marginRight: '-5px'}}>
                          <img alt="close" src={CloseIcon} onClick={() => setShowStartAgainModal(false) }   className="close-btn" width="12" />
                        </div>
                        <div className="modal-contentt">
                          <div className="row m-0">
                              <div className="col-12" >
                                <div>
                                  It is {statsSEOTotal-statsSEOGenerated} {statsSEOTotal-statsSEOGenerated > 1 ? "products" : "product"} that fit your creteria
                                </div>
                                <div className="pt-3">
                                    <input className="checkbox" type="checkbox"  />&nbsp; 
                                   Also include already optimized products ({statsSEOGenerated} optimized, total {statsSEOTotal})
                                </div>
                                
                              </div>
                          </div>
                          <div className="row m-0">
                            <div className="col-4" >
                              <div className="pt-3">
                                 <button  onClick={() => StopResumeWorkPlan(startAgainID, 'pending') } className="btn btn-md fm-btn me-md-2">{t('startagain')} </button>
                              </div>
                            </div>
                            <div className="col-4" >
                              <div className="pt-3">
                                 <button  onClick={() => setShowStartAgainModal(false) } className="btn btn-md fm-btn me-md-2">{t('cancel')} </button>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                    : ''}  
                  
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default WorkSchedule;