import {useParams, NavLink  } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AssistantService from "../services/AssistantService";
import UserService from "../services/UserService";
import PIMServices from "../services/PIMServices";
import ProductService from "../services/ProductService";
import DropdownComponent from "./DropdownComponent";
import "../styles/bootstrap.min.css";
import CreditBar from "./CreditBar";
import TopBar from "./Topbar";
import SideBar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'; // Import the CSS for styling
import moment from 'moment';
import { Dropdown } from 'semantic-ui-react'
import MyLoader from "./MyLoader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//import 'semantic-ui-css/semantic.css';
function WorkPlan() {
  const { appslug } = useParams();

  document.body.style.backgroundColor = '#FFFFFF';
  const [scheduleMsg, setScheduleMsg] = useState("");
 // const [selectedDate, setSelectedDate] = useState(null);
  const [organizationID, setOrganizationID] = useState(parseInt(window.sessionStorage.getItem('organization_id'), 10));
 
  const [scheduleISBN, setScheduleISBN] = useState("");
  const [totalISBNCount, setTotalISBNCount] = useState("");
  const [assistantID, setAssistantID] = useState("");
  const [dailyWeeklyMonthly, setDailyWeeklyMonthly] = useState();
  const [attributesList, setAttributesList] = useState();
  const [monthDay, setMonthDay] = useState();
  const [showSEOIntervals, setShowSEOIntervals] = useState(false);
  const [showMassSEOCriteria, setShowMassSEOCriteria] = useState(true);
  const [showMassSEOSchedule, setShowMassSEOSchedule] = useState(true);
  const [selectedTime, setSelectedTime] = useState();
  const [saveFrequencyMsg, setSaveFrequencyMsg] = useState();
  const [saveMassFrequencyMsg, setSaveMassFrequencyMsg] = useState();
  const [lastCronTime, setLastCronTime] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [attributeList, setAttributeList] = useState([]);
  const [attributeSEOStatsTotal, setAttributeSEOStatsTotal] = useState();
  const [attributeSEOStats, setAttributeSEOStats] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [massAttributeIDs, setMassAttributeIDs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [savedProductAttributeGroup, setSavedProductAttributeGroup] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [showCriteriaStats, setShowCriteriaStats] = useState(false);
  const [statsSEOGenerated, setStatsSEOGenerated] = useState("");
  const [statsSEOTotal, setStatsSEOTotal] = useState("");
  const [showAutoSEONextStep, setShowAutoSEONextStep] = useState(false);
  const [showManualSEONextStep, setShowManualSEONextStep] = useState(false);
  const [includeAllCriteria, setIncludeAllCriteria] = useState("no")
  const [atrributeItemWaiting, setAtrributeItemWaiting] = useState(false)
  const [totalProductsInCriteria, setTotalProductsInCriteria] = useState(0)
  const [totalCredit, setTotalCredit] = useState();
  const [creditUsed, setCreditUsed] = useState();
  const [showScheduleForLater, setShowScheduleForLater] = useState(false);
  console.log("selectedOption", selectedOption)

  

  useEffect(() => {

    setSaveFrequencyMsg("")
    PIMServices.PIMAttributes(organizationID).then(
      (response) => {
        setAttributesList(response.data)
      }
    ).catch(
      (error) => {
        console.log("error fetching attributes", error);
      }
    )

    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
          setAssistantID(assistresponse.assistantID)

          

          /*AssistantService.GetSEOFrequency(assistresponse.assistantID).then(
            (response) => {
              const parsedLastCronDate = new Date(response.seofrequency.last_cron_date);
              const currentDate = new Date();
              const differenceInMillis = currentDate - parsedLastCronDate;
              const differenceInHours = Math.floor(differenceInMillis / (1000 * 60 * 60));
              setShowSEOIntervals(true)
              setDailyWeeklyMonthly(response.seofrequency.frequency)
              setSelectedTime(response.seofrequency.time)
              setMonthDay(response.seofrequency.days)
              setSelectedDays(JSON.parse(response.seofrequency.days))
              //const idsArray = response.seofrequency.attribute_ids.split(',').map(Number); // Split and convert to numbers
              //setSelectedOption(idsArray);
              setLastCronTime(differenceInHours)
              //console.log("response", JSON.parse(response.pimfrequency.days));
            }
          ).catch(
            (error) => {
              console.log("Error fetching SEO Frequency", error)
            }
          )*/
      }
    ).catch(
      (error) => {
        console.log("error getting assitant ID", error);
      }
    )
    setAtrributeItemWaiting(true)
    PIMServices.GetPIMFrequency(organizationID).then(
      (response) => {
        setSelectedAttribute(response.attributes)
        response.attributes.map((values, keys) => {

          AssistantService.GetAttributeGroupValuesById(values.id).then(
            (response) => {
                //console.log("response", response.data.attributes);
                setAttributeList(prevList => {
                // Create a new group for each `values.id`
                const newGroup = {
                  id: values.id,
                  name: values.name,
                  items: [
                    { value: parseInt(values.id+"0000000000", 10), text: "All" }, // Use `0` for the "All" option
                    ...Array.isArray(response.data.attributes)
                      ? response.data.attributes.map(val => ({
                          value: val.id,
                          text: val.attribute_value
                        }))
                      : []
                  ]
                };
                const updatedList = prevList.filter(group => group.id !== values.id);
                  // Assign the new list at index 0
                   return [...(updatedList || []), newGroup];
                });
                setAtrributeItemWaiting(false)
              
            }
          ).catch(
            (error) => {
              console.log("error getting attributes values", error);
            }
          )
        })
      }
    ).catch(
      (error) => {
        console.log("Error fetching PIM frequency", error)
      }
    )

    AssistantService.getAssistantIDBySlug(appslug).then(
      (assistresponse) => {
        setTotalCredit(assistresponse.credits)
        setCreditUsed(assistresponse.consumed)
      }
    )

  }, [appslug])
  //console.log("assistantID", assistantID)
  const [selectedDays, setSelectedDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
   const selectWeeklyDays = (day) => {
    setSelectedDays((prevSelectedDays) => ({
      ...prevSelectedDays,
      ...day,
    }));
  };
  //console.log("selectedTime", selectedTime.format('hh:mm:ss'));
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const handleMonthDayChange = (event) => {
    setMonthDay(event.target.value);
  }
  const handleSelectChange = (event) => {
    setSelectedDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    })
    setDailyWeeklyMonthly(event.target.value);
  };
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Pad single digits with a leading zero
    return `${hour}:00`;
  });
  const handleTimeChange = (event) => {
    /*if (time) {
        // Set the minutes to 00
        const adjustedTime = time ? time.minutes(0) : null;*/
        setSelectedTime(event.target.value+':00');
    //}
    ///setSelectedTime(value);
  };
  const { t } = useTranslation();
  const saveSEOCriteriaAndSchedule = () => {
    /*const params = {
      frequency : dailyWeeklyMonthly,
      days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
      time : selectedTime,
    }*/
    AssistantService.SetSEOCriteriaAndSchedule(organizationID, assistantID, selectedDate, selectedOption.join(',')).then(
      (response) => {
        console.log(response);
        setSaveMassFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO Mass frequency", error);
      }
    )
  }

  const saveSEOFrequency = (schedulenow) => {
    const params = {
      frequency : dailyWeeklyMonthly,
      days : dailyWeeklyMonthly=='weekly' ? JSON.stringify(selectedDays) : monthDay,
      time : selectedTime,
    }
    console.log("saveSEOFrequency", organizationID, assistantID, dailyWeeklyMonthly, params.days, params.time, selectedOption.join(','), schedulenow, includeAllCriteria, totalProductsInCriteria)
    AssistantService.SetSEOFrequency(organizationID, assistantID, dailyWeeklyMonthly, params.days, params.time, selectedOption.join(','), schedulenow, formatDateTime(selectedDate), includeAllCriteria, totalProductsInCriteria).then(
    //AssistantService.SetSEOFrequency(organizationID, assistantID, formatDateTime(selectedDate), selectedOption.join(','), schedulenow, includeAllCriteria, totalProductsInCriteria).then(
      (response) => {
        console.log(response);
        setSaveFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        console.log("Error setting SEO frequency", error);
      }
    )
    //console.log(dailyWeeklyMonthly, selectedDays, monthDay, selectedTime.format('hh:mm:ss'));
  }
  

  const handleChange = (event) => {
    // Update the state with the new input value
    setScheduleISBN(event.target.value);
  };
  const addISBNSchedule = (schedulenow) => {
    ProductService.addSchedule(organizationID, assistantID,  formatDateTime(selectedDate), scheduleISBN, schedulenow).then(
      (response) => {
        setSaveFrequencyMsg(response.message);
      }
    ).catch(
      (error) => {
        //setScheduleMsg(error.response.data.error);
        console.log("Error setting schedule", error);
      }
    )
  }
  const formatDateTime = (date) => {
    if (!date) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  /*const handleDateChange = (date) => {
    setStartDate(date)
    // Update the state with the selected date
    const formattedDate = date 
      ? `${date.toLocaleDateString('en-CA')} ${date.toLocaleTimeString('en-GB', { hour12: false })}`
      : '';
      setSelectedDate(formattedDate); 
    console.log("Formatted Date:", formattedDate);
  };*/

    const handleDropDownChange = (event, data, attribGroupID) => {
      console.log("data.value", data.value, attribGroupID);
      setSelectedOption(data.value)

      //const oldValues = document.getElementById('seostats-text-').value
      AssistantService.GetAttributeValueSEOStats(data.value, assistantID).then(
        (response) => {
          setShowCriteriaStats(true)
          setTotalProductsInCriteria(response.data.totalProducts-response.data.seoGeneratedCount)
          setStatsSEOGenerated(response.data.seoGeneratedCount)
          setStatsSEOTotal(response.data.totalProducts)
          document.getElementById('seostats-text').value = data.value;
          setMassAttributeIDs(prevArray => {

            // Check if attribGroupID already exists in the array
            const existingEntry = prevArray.find(entry => entry.attribGroupID === attribGroupID);

            if (existingEntry) {
              // If the value is not already present for this attribGroupID, add it
              if (!existingEntry.values.includes(data.value)) {
                return prevArray.map(entry => 
                  entry.attribGroupID === attribGroupID
                   ? { ...entry, values: [ data.value] }
                    : entry
                );
              }
            } else {
              // If attribGroupID doesn't exist, add a new entry for attribGroupID with the new value
              return [...prevArray, { attribGroupID, values: [data.value] }];
            }

            return prevArray; // Return unchanged if the value already exists
          });
        }
      ).catch(
        (error) => {
          setShowCriteriaStats(false)
          console.log("error fetching data from GetAttributeValueSEOCount", error)
          //document.getElementById('seostats-number').innerHTML = "";
          document.getElementById('seostats-text').value = "";
          setMassAttributeIDs(prevArray => {
              // Filter out the entry with the specified attribGroupID
              const updatedArray = prevArray.filter(entry => entry.attribGroupID !== attribGroupID);

              return updatedArray;
          });
        }
      )
    };
    
    const calculateISBNCount = () => {
      setTotalISBNCount(scheduleISBN.split(',').filter(isbn => isbn.trim() !== "").length)

    }
    /*const setIncludeAllCriteria = (event) => {
      console.log("event.target.value", event.target.value)
    }*/
	return (
        <div  >
            <div className="container-fluid">
              <div className="row">
                

                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap d-none d-block align-items-center pt-3 pb-2 mb-3">
                      <button className="navbar-toggler bg-light position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="bi bi-list"></i>
                          </button>
                    </div>
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-1">
                    <div className="col-md-3 col-lg-7 px-4 ">
                      <h1 className="h1">{t('workschedule')}</h1>
                      <div className="breadcrumb">
                        {t('workschedule')} 
                      </div>
                    </div>
                    <CreditBar /><TopBar />
                  </div>
                  
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-0 pb-0 mb-3">
                    <div className="col-md-3 col-lg-8 px-4 ">
                    <p>{t('workschedulepagesubline')}</p>
                    </div>
                  </div>
                  <div className="container-fluid">
                  <hr />
                  </div>
                  <div className="container-fluid mt-4">
                    <div className="row">
                      <div className="">
                        <h4 className="h4">{t('automaticseooptimize')}</h4>
                      </div>
                    </div>
                     {/*
                    <div className="row" >
                     
                      <div className="col-4" >
                        <div className="pt-2">
                         Last SEO update: {lastCronTime} hours ago. Next update happens in {24-lastCronTime} hours.
                        </div>
                      </div>

                      <div className="col-2 text-end" >
                        <NavLink to={"/"+appslug+"/stop"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('review')}</button></NavLink>
                        <NavLink to={"/"+appslug+"/review"}><button className="btn btn-md fm-btn me-md-2" type="button" >{t('stop')}</button> </NavLink>
                      </div>
                     
                    </div>
                     */}
                    {showAutoSEONextStep || showManualSEONextStep ? 
                      (
                      <>
                        {/* <div className="row" >
                          <div className="mt-4">
                          <h6 className="h5">{t('choosetimeforupdate')}</h6>
                            
                          </div>
                        </div> */}
                        <div className="row" >
                          <div className="mt-4">
                            {showAutoSEONextStep ?
                            (
                            <>
                              {totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? 
                              (
                              <>
                                This workplan will consume {includeAllCriteria=="yes" ? statsSEOTotal : statsSEOTotal-statsSEOGenerated} credits
                              </>
                              ) : (
                              <>
                                <p className="red">This workplan require {includeAllCriteria=="yes" ? statsSEOTotal : statsSEOTotal-statsSEOGenerated} credits. You have {totalCredit-creditUsed} available.</p>
                              </>
                              )
                              }
                            </>
                            ) : (
                            <>
                              {totalCredit-creditUsed > totalISBNCount ? 
                                (
                                <>
                                 This workplan will consume {totalISBNCount} credits
                                </>
                                ) : (
                                  <>
                                  <p className="red">This workplan require {totalISBNCount} credits. You have {totalCredit-creditUsed} available.</p>
                                  </>
                                )
                              }
                            </>
                            )}
                          </div>
                        </div>
                        <div className="row mt-4" >
                          <div className="float-left w-auto ">
                            <button disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} onClick={() => saveSEOFrequency('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                          </div>
                          
                          <div className="float-left mt-1 w-auto">
                            <div  onClick={() => setShowScheduleForLater(!showScheduleForLater)} style={{color: "#95508F ", cursor: "pointer"}}>{t('scheduleforlater')}</div>
                          {/*<button  onClick={() => setShowScheduleForLater(!showScheduleForLater)} className="btn btn-md fm-btn me-md-2">{t('scheduleforlater')}</button> */}
                          </div>
                        </div>
                        
                        <div className="row" >
                          <div className="mt-4">
                            {showScheduleForLater ? 
                              <><DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={60}
                              timeCaption="Time"
                              className="form-control" 
                              dateFormat="MMMM d, yyyy h:mm aa"
                              placeholderText="Select a date and time"
                            />
                            <div className="float-left mt-4 ">
                              <button disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} onClick={() => saveSEOFrequency('now')} className="btn btn-md fm-btn me-md-2">{t('save')}</button>
                            </div>
                            </> : "" }
                          </div>
                        </div>
                        <div className="row" >
                          <div className="mt-4">
                            <hr />
                          </div>
                        </div>

                        <div className="row" >
                          <div className="mt-4">
                          <h6 className="h5">{t('chooseinterval')}</h6>
                            
                          </div>
                        </div>
                        <div className="row" >
                          <div className="col-7 mt-4">
                          <h6 className="h6">{t('chooseintervalsubline')}</h6>
                            
                          </div>
                        </div>
                        <div className="row pt-4" >
                          <div className="col-1" >
                            
                            <select className="form-control" onChange={handleSelectChange}>
                              <option value="">Select</option>
                              <option value="hourly" selected={dailyWeeklyMonthly=="hourly" ? "selected" : ""}>Hourly</option>
                              <option value="daily" selected={dailyWeeklyMonthly=="daily" ? "selected" : ""}>Daily</option>
                              <option value="weekly" selected={dailyWeeklyMonthly=="weekly" ? "selected" : ""}>Weekly</option>
                              <option value="monthly" selected={dailyWeeklyMonthly=="monthly" ? "selected" : ""}>Monthly</option>
                            </select>
                          </div>
                          {/*<div className="col-1" >
                          <h6 className="h5">OR</h6>
                          </div>
                          <div className="col-4" >
                          {showAutoSEONextStep ? 
                            (
                            <>
                              <button disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} onClick={() => saveSEOFrequency('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                            </>
                            ) : (
                            <>

                              <button disabled={totalCredit-creditUsed > totalISBNCount ? "" : "disabled"} onClick={() => addISBNSchedule('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                            </>
                            )}
                          </div> */}
                          <div className="col-4" >
                            {dailyWeeklyMonthly=="hourly" ?
                            (
                            <></>)
                            : dailyWeeklyMonthly=="daily" ?
                            (
                            <div className="row">
                              <div className="col-3">
                              <select className="form-control" onChange={handleTimeChange} >
                                {times.map((time, index) => (
                                  <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              </div>
                             </div>)
                            : dailyWeeklyMonthly=="weekly" ?
                              (
                              <div className="row">
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.monday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({monday: !selectedDays.monday})}>M</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.tuesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({tuesday: !selectedDays.tuesday})}>T</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.wednesday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({wednesday: !selectedDays.wednesday})}>W</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.thursday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({thursday: !selectedDays.thursday})}>T</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.friday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({friday: !selectedDays.friday})}>F</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.saturday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({saturday: !selectedDays.saturday})}>S</div>
                                <div  className={`col-1 p-0 weeklydays ml-1 ${selectedDays.sunday ? 'selectedDay' : ''}`} onClick={() => selectWeeklyDays({sunday: !selectedDays.sunday})}>S</div>
                                <div className="col-3">
                                  <select className="form-control" onChange={handleTimeChange} >
                                    {times.map((time, index) => (
                                      <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                </div>)
                            : dailyWeeklyMonthly=="monthly" ?
                              (<div className="row">
                                <div  className="col-3">
                                
                                <select className="form-control" onChange={handleMonthDayChange}>
                                  <option value="">Select Day</option>
                                  {days.map((day) => (
                                    <option key={day} value={day} selected={monthDay==day ? "selected" : ""}>
                                      {day}
                                    </option>
                                  ))}
                                </select>
                                </div>
                                <div className="col-3">
                                  <select className="form-control" onChange={handleTimeChange} >
                                    {times.map((time, index) => (
                                      <option selected={selectedTime==time+':00' ? "selected" : ""} key={index} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                  
                                </div>
                              </div>)
                            : ''
                            }
                          </div>
                          
                        </div>
                        <div className="row pt-4" >
                          <div className="col-12">
                            {showAutoSEONextStep ? 
                            (
                            <>
                              <button  disabled={totalCredit-creditUsed > statsSEOTotal-statsSEOGenerated ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => saveSEOFrequency('schedule')} >{t('save')}</button>
                            </>
                            ) : (
                            <>
                              <button  disabled={totalCredit-creditUsed > totalISBNCount ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => addISBNSchedule('schedule')}  >{t('save')}</button>
                            </>
                            )}
                            
                            {saveFrequencyMsg ? saveFrequencyMsg : ''}
                          </div>
                        </div>
                        <div className="row pt-4" >
                          <div className="col-12">
                            <button className="btn btn-md fm-btn me-md-2" type="button" onClick={() => {setShowAutoSEONextStep(false);  setShowManualSEONextStep(false);}} >{t('previousstep')}</button>  
                          </div>
                        </div>
                      </>
                      )
                      : (
                      <>
                      <div className="row" >
                        <div className="mt-4">
                          <h6 className="h5">{t('selectcriteriaformassupdates')}</h6>
                          {/* }<button className="btn btn-lg fm-btn me-md-2" type="button" onClick={() => setShowMassSEOCriteria(!showMassSEOCriteria)} >{t('selectcriteriaformassupdates')}</button> {*/}
                        </div>
                      </div>

                      {showMassSEOCriteria ? 
                      (
                      <>
                      <div className="row pt-4" >
                        <div className="col-6" >
                           {atrributeItemWaiting ? ( <><MyLoader /></>  ) :
                            attributeList ? attributeList.map((value, key) => (
                            <div className="row pt-2">
                              <div className="col-3 attribute-label">
                               {value.name}:
                              </div>
                              <div className="col-9">
                                <Dropdown value={selectedOption} placeholder={"Velge "+value.name} fluid search multiple clearable selection  onChange={(event, data) => handleDropDownChange(event, data, value.id)} options={value.items} />
                              </div>
                              
                              
                            </div>
                          )) : ""}
                        </div>
                      </div>
                      <div className="row" >
                        <div className="col-12 pt-4"  >
                          {showCriteriaStats ?
                            (
                            <>
                              <div className="col-6" >
                                <div>
                                It is {statsSEOTotal-statsSEOGenerated} {statsSEOTotal-statsSEOGenerated > 1 ? "products" : "product"} that fit your creteria
                                </div>
                                <div className="pt-3">

                                  <input className="checkbox" type="checkbox" checked={includeAllCriteria=="yes" ? "checked" : ""} value={includeAllCriteria} onChange={(e) =>{ setTotalProductsInCriteria(e.target.checked ? statsSEOTotal : statsSEOTotal-statsSEOGenerated); setIncludeAllCriteria(e.target.checked ? "yes" : "no")}} />&nbsp; 
                                   Also include already optimized products ({statsSEOGenerated} optimized, total {statsSEOTotal})
                                </div>
                              </div>
                            </>
                            )
                          : ""}
                           
                           
                           <div className="col-6" >
                           <input size="10" type="hidden" id="seostats-text" />
                           </div>
                        </div>
                      </div>
                      <div className="row" >
                        <div className="col-12 pt-4"  >
                          <div className="col-12">
                            <button disabled={selectedOption.length > 0 ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => setShowAutoSEONextStep(true)} >{t('nextstep')}</button>     
                          </div>
                        </div>
                      </div>
                      </>
                      )
                      : ""}
                    </>)}
                    
                    {showSEOIntervals ? 
                    (
                    <>
                    
                    </>
                    )
                    : ''}
                  </div>
                  {showAutoSEONextStep || showManualSEONextStep ? (<></>) : 
                    (
                      <>
                        <div className="container-fluid mt-4">
                          <hr />
                        </div>
                        <div className="container-fluid mt-4">
                          {/* <div className="row" >
                            <div>
                              <h4 className="h4">{t('maunalseooptimize')}</h4>
                            </div>
                          </div> */}
                          <div className="row" >

                            <div className="col-6 pt-4" >
                              <div className="col-12"><h5>{t('schedulebyproduct')}</h5></div>
                              <div className="col-12">
                                <div className="input-group mb-3">
                                  <textarea className="form-control" onChange={handleChange}  >
                                  
                                  </textarea>
                                  {/*
                                    <input type="text" value={scheduleISBN} onChange={handleChange} className="form-control" placeholder="" aria-label="" aria-describedby="" />
                                  <button onClick={() => addSchedule()} className="btn fm-btn" type="button">{t('startupdate')}</button> */}
                                </div>
                              </div>
                              {scheduleMsg ? scheduleMsg :''}
                            </div>
                          </div>
                          <div className="row" >
                            <div className="col-12 pt-4"  >
                              <div className="col-12">
                                <button disabled={scheduleISBN.length > 0 ? "" : "disabled"} onClick={() => addISBNSchedule('now')} className="btn btn-md fm-btn me-md-2">{t('startnow')}</button>
                                {saveFrequencyMsg ? saveFrequencyMsg : ''}
                                {/* <button disabled={scheduleISBN.length > 0 ? "" : "disabled"} className="btn btn-md fm-btn me-md-2" type="button" onClick={() => { setShowManualSEONextStep(true); calculateISBNCount() } } >{t('nextstep')}</button>   */}  
                              </div>
                            </div>
                          </div>
                        </div>
                        </>
                    )
                  }
                  
                  
                  
                
                </main>
              </div>
            </div>
        	
        </div>
        )
}
export default WorkPlan;