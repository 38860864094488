import axios from "axios";

class PromptService {
    
    savePrompt(assistantID, type, prompt, organizationID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                    "prompt_type": type,
                    "prompt": JSON.stringify(prompt),
                    "assistant_id": assistantID,
                    "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "saveprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }


    addSystemDefinedPrompt(organizationID, assistantID, prompt) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "addsystemprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    editSystemDefinedPrompt(organizationID, assistantID, prompt, indexKey) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "editsystemprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    editUserOneDefinedPrompt(organizationID, assistantID, prompt, indexKey, column) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "edituseroneprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    addUserOneDefinedPrompt(organizationID, assistantID, prompt, column) {
        //return assistantID+' - '+prompt+' - '+indexKey+' - '+column;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "prompt": JSON.stringify(prompt),
                "assistant_id": assistantID,
                "organization_id": organizationID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "adduseroneprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }
    deleteSystemDefinedPrompt(assistantID, indexKey) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "assistant_id": assistantID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "deletesystempromptquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    deleteUserOnePrompt(assistantID, indexKey, column) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "index_key": indexKey,
                "assistant_id": assistantID,
                "column": column
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "deleteuseronepromptquestion",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    saveTempPrompt(assistantID, type, prompt) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                    "prompt_type": type,
                    "prompt": JSON.stringify(prompt),
                    "assistant_id": assistantID
                
            },
            url: process.env.REACT_APP_API_SERVER_URL + "savetempprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getPromptByType(assistantID, type, organizationID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"prompt_type": type, "assistant_id": assistantID, "organization_id": organizationID},
            url: process.env.REACT_APP_API_SERVER_URL + "getprompt",
        } 
        return axios(options)
            .then(response => {
                return response.data
            })
    }

    getPromptByAssisstant(assistantID) {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "promptlist",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    promptHistory(assistantID){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"assistant_id": assistantID},
            url: process.env.REACT_APP_API_SERVER_URL + "prompthistory",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    promptRestore(jobid){
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {"id": jobid},
            url: process.env.REACT_APP_API_SERVER_URL + "promptrestore",
        } 
        return axios(options)
        .then(response => {
            return response.data
        })
    }

    
    

}

export default new PromptService();